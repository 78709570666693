<template>
<div>
  <h1>todolist</h1>
  <ul>
    <li
      v-for="(item, index) in itemList"
      :key="index"
      :class="{'done': item.status}"
    >
      <input type="checkbox" v-model="item.status">

      <div class="input_wrp">
        <input
          :ref="el => (inputRefs[index] = el)"
          type="text"
          v-model="item.content"
          :data-id="item.id"
          @keyup.enter="enterData($event, index)"
          @blur="blurData($event, index)"
        >
      </div>

      <button v-if="!isOnly" type="button" @click="deleteData($event, index)">删除</button>
    </li>
  </ul>
</div>
</template>
<script setup>
import { ref, reactive, watch, computed, nextTick, onMounted } from 'vue';
import axios from 'axios';

// 输入框新增
const itemList = ref([]);
const inputRefs = reactive([]);

const isOnly = computed(() => {
  return itemList.value.length === 1;
});



// enter输入框
const enterData = async (e, index) => {
  try {
    console.log('enter begin');

    const inputValue = e.target.value;
    const inputStatus = itemList.value[index].status;
    const inputID = e.target.getAttribute('data-id');

    if(inputValue){
      const response = await axios.post('/api/new-data');
      const newID = response.data.lastID;
      itemList.value.push({id: newID, content: '', status: false});
      await nextTick();
      inputRefs[itemList.value.length - 1].focus();

      console.log('lastID:', newID);
      inputRefs.forEach((item, index) => {
        if(item){
          let dataID = item.getAttribute('data-id');
          console.log(`input ${index} data-id: ${dataID}`);
        }else{
          console.log(`input ${index} is null`);
        }
      });
    }else{
      console.log('enter end novalue');
    }
  } catch (err) {
    console.error(err)
    alert('fail to add item.')
  }
}


// blur输入框更新数据
const blurData = async (e, index) => {
  try {
    console.log('blur begin');

    const inputValue = e.target.value;
    const inputStatus = itemList.value[index].status;
    const inputID = e.target.getAttribute('data-id');

    console.log('blur input index: ', index);
    console.log('blur input id: ', inputID);
    console.log('blur input value: ', inputValue);
    console.log('blur input status: ', inputStatus);

    const res = await axios.post('/api/update-data', { id: inputID, content: inputValue, status: inputStatus ? 1 : 0 });

    console.log('blur end');

    const response = await axios.post('/api/all-data');
    console.log('all data after blur: \n', response.data);
    console.log('itemList after blur: \n', itemList.value);
  } catch (err) {
    console.error(err)
    alert('fail to update item.')
  }
}


// 删除数据
const deleteData = async (e, index) => {
  try {
    console.log('delete begin.')
    const inputID = inputRefs[index].getAttribute('data-id');

    const checkid = await axios.post('/api/checkid', { id: inputID });
    checkid && await axios.post('/api/delete-data', { id: inputID });

    itemList.value.splice(index, 1);
    console.log('delete end.')

    const response = await axios.post('/api/all-data');
    console.log('all data after delete: \n', response.data);
    console.log('itemList after delete: \n', itemList.value);
  } catch (err) {
    console.error(err)
    alert('fail to delete item.')
  }
}


// 监听status变化
watch(
  () => itemList.value.map(item => item.status),
  (newValue, oldValue) => {
    console.log('watch begin \n');
    newValue.forEach(async (st, index) => {
      if(st !== oldValue[index]){
        const item = itemList.value[index];
        if(item.content){
          try{
            await axios.post('/api/update-status', { id: item.id, status: st ? 1 : 0 });

            const response = await axios.post('/api/all-data');
            console.log('all data after status: \n', response.data);
            console.log('itemList after status: \n', itemList.value);
          } catch (err) {
            console.log(err);
            alert("fail to updata status");
          }
        }
      }
    });
  },
  { deep: true }
);


// 获取数据库数据并更新输入框列表
const fetchData = async () => {
  try {
    console.log('fetch begin');

    const response = await axios.post('/api/all-data');
    console.log('all data before fetch: \n', response.data);
    console.log('itemList before fetch: \n', itemList.value);

    itemList.value = response.data;
    itemList.value = response.data.map(item => {
      item.status = item.status === 1;
      return item;
    });

    console.log('fetch end');
    console.log('all data after fetch: \n', response.data);
    console.log('itemList after fetch: \n', itemList.value);
  } catch (err) {
    console.error(err);
    alert('Failed to fetch data.');
  }
};


// 页面刷新时获取数据库数据
onMounted(fetchData);
</script>
<style lang="less">
li{
  list-style:none;
}
.input_wrp{
  display:inline-flex;
}
input{
  border:0;
}
.done{
  .input_wrp{
    position:relative;

    input{
      color:rgba(0,0,0,.55);
    }
    &:after{
      content:"";
      height:1px;
      background:gray;
      position:absolute;
      top:~"calc(50% - 1px)";
      left:4px;
      right:4px;
    }
  }
}
.mask{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:rgba(0,0,0,.5);
}
</style>
